import { Typography } from 'antd';
import { useMemo } from 'react';
import UploadFileCustom from '../../../../common/components/UploadFile';
import './inspectionCustomer.style.scss';

const { Text } = Typography;

// Utility function to group the array by a specified key
const groupBy = (array, key) => {
  return array.reduce((result, currentItem) => {
    const groupKey = currentItem[key];
    if (!result[groupKey]) {
      result[groupKey] = [];
    }
    result[groupKey].push(currentItem);
    return result;
  }, {});
};

const InspectionCustomerModal = ({
  data,
  inspectionCustomerBtn,
}) => {
  const images = data?.inspection_refund?.pictures || [];

  // Memoize the grouped images to optimize performance
  const groupedImages = useMemo(() => groupBy(images, 'index'), [images]);
  // Check if the inspection date is expired after 2 weeks
  const today = new Date();
  const todayDateOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  const inspectionDate = data?.uploaded_inspection_date ? new Date(data.uploaded_inspection_date) : null;
  const inspectionDateOnly = inspectionDate
    ? new Date(inspectionDate.getFullYear(), inspectionDate.getMonth(), inspectionDate.getDate())
    : null;
  const twoWeeksInMs = 14 * 24 * 60 * 60 * 1000;
  const isExpired = inspectionDateOnly && todayDateOnly - inspectionDateOnly >= twoWeeksInMs;
  // Convert the groupedImages object into an array of [key, value] pairs and sort by key
  const sortedGroupedImages = useMemo(() => {
    return Object.entries(groupedImages).sort((a, b) => a[0] - b[0]);
  }, [groupedImages]);

  return (
    <>
      <div className="w-100 inspection-customer-modal">
        {!isExpired ? (
          <>
            {sortedGroupedImages?.map(([groupIndex, group]) => (
              <>
                <div key={groupIndex} style={{ marginBottom: '12px' }}>
                <Text strong>검수 사진{groupIndex}</Text>
                <div
                  className="w-100"
                  style={{ border: '1px solid #D0D5DD', borderRadius: 5, padding: 12, marginTop: 5 }}>
                  <UploadFileCustom value={group} isViewOnly />
                </div>
              </div>
            </>
            ))}
            <ul>
              <li>
                <Text>{'이미지를 클릭하여 확대할 수 있습니다.'}</Text>
              </li>
              <li>
                <Text>{'검수 사진은 2주간 확인할 수 있습니다. 필요시 사진을 저장해 주세요'}</Text>
              </li>
              <li>
                <Text>{'검수 사진에 대해 자세한 설명을 원한다면 contact@free-t.co.kr로 문의주세요.'}</Text>
              </li>
            </ul>
          </>
        ) : (
          <>
            <div style={{ textAlign: 'center' }}>
              <img
                src={process.env.PUBLIC_URL + '/loading_images.png'}
                alt="Expired Inspection"
                style={{ width: '146px', height: 'auto' }}
              />
              <Text
                style={{
                  display: 'block',
                  marginTop: '10px',
                  textAlign: 'center',
                  fontSize: 16,
                  fontWeight: 700
                }}>
                해당 검수 사진은 <br /> 보관 기간이 만료되어 삭제되었습니다.
              </Text>
            </div>
          </>
        )}
      </div>
      {inspectionCustomerBtn || <></>}
    </>
  );
};

export default InspectionCustomerModal;
