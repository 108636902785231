import { Button, Form, Input, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginThunk } from '../../../redux/reducers/authSlice';
import { CheckOutlined } from '@ant-design/icons';
import './style.scss';
import { regX_password, getOptionsValidPassword } from '../../../utility/utils';
import ForgotPasswordDrawer from './ForgotPasswordDrawer';
import SignUpOptionDrawer from './SignUpOptionDrawer';
import { LogoIcon } from '../../../assets/icons/Icons';

const { Text } = Typography;

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [forgotPasswordDrawerOpened, setForgotPasswordDrawerOpened] = React.useState(false);
  const [signUpOptionDrawerOpened, setSignUpOptionDrawerOpened] = React.useState(false);
  const { loading, isLogin } = useSelector((state) => state.auth);

  const isCheckValidForm = (values) => {
    const isValidPassword = values.password && regX_password.test(values?.password);
    return isValidPassword && values?.username;
  };

  useEffect(() => {
    if (isLogin) {
      // navigate('/refund-request', { replace: true });
      window.location.href = '/refund-request';
    }
  }, [isLogin]);

  const handleSubmit = (values) => {
    dispatch(loginThunk(values));
  };

  return (
    <div className="loginPage">
      <div className="content">
        <div className="logo">
          <LogoIcon width={201} height={52} />
        </div>
        <Form name="login" layout="vertical" colon={false} onFinish={handleSubmit}>
          <Form.Item
            label="이메일"
            name="username"
            rules={[{ type: 'email', message: '이메일 형식이 올바르지 않아요' }]}>
            <Input size="large" />
          </Form.Item>
          <Form.Item label="비밀번호" name="password" style={{ marginBottom: '8px' }}>
            <Input.Password size="large" />
          </Form.Item>
          <Form.Item shouldUpdate={(preValue, curValue) => preValue.password !== curValue.password}>
            {({ getFieldValue }) => {
              const config = getOptionsValidPassword(getFieldValue('password'));
              const item = config.map((item) => (
                <div key={item.item} className="d-flex ms-1" style={{ color: item.isValid ? '#1570EF' : '#98A2B3' }}>
                  <CheckOutlined /> <div className="ms-1">{item.description}</div>
                </div>
              ));
              return <div className="d-flex">{item}</div>;
            }}
          </Form.Item>
          <Text
            underline
            onClick={() => setForgotPasswordDrawerOpened(true)}
            style={{ display: 'block', textAlign: 'end', fontWeight: 700 }}>
            비밀번호를 잃어버렸어요
          </Text>
          <Form.Item shouldUpdate style={{ marginTop: '24px' }}>
            {({ getFieldsValue }) => (
              <Button
                htmlType="submit"
                block
                type="primary"
                loading={loading}
                size="large"
                disabled={!isCheckValidForm(getFieldsValue())}>
                로그인
              </Button>
            )}
          </Form.Item>
          <Text style={{ display: 'block', textAlign: 'center' }}>
            계정이 없으신가요?{' '}
            <Text underline onClick={() => setSignUpOptionDrawerOpened(true)} style={{ fontWeight: 700 }}>
              회원가입
            </Text>
          </Text>
        </Form>
      </div>
      <ForgotPasswordDrawer open={forgotPasswordDrawerOpened} onClose={() => setForgotPasswordDrawerOpened(false)} />
      <SignUpOptionDrawer open={signUpOptionDrawerOpened} onClose={() => setSignUpOptionDrawerOpened(false)} />
    </div>
  );
};

export default Login;
